.lower-header {
  color: #fff;
  display: block;
  padding: 10px;
  padding-left: 10px;
  position: absolute;
  bottom: 20px;
  left: 15px;
}

.picture-background {
  background: (linear-gradient rgba(255, 0, 0, 0.45), rgba(255, 0, 0, 0.45));
}

.picture {
  position: relative;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  height: 230px;
  margin-bottom: 20px;
  background-image: url("../img/Hs_hub_Banner.jpg");
  background-repeat: round;
}

.bottom-bar {
  position: absolute;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 15px;
  height: 70px;
  border-top: 2px solid;
  border-color: rgb(231, 91, 39);
}

.bold-text {
  font-weight: bold;
}

.left {
  float: left;
}

.right {
  float: right;
  border: 1px solid white;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.right:hover {
  float: right;
  background: white;
  color: black;
}

.black-text {
  color: black;
}

.image-container {
  position: relative;
  height: 233px;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
}
.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.image-container .after .lower-header {
  transition: all 0.2s ease-in-out;
}

.image-container:hover .after {
  background: rgba(0, 0, 0, 0.75);
}

.image-container:hover .after .lower-header {
  bottom: 180px;
}

.image-container:hover img {
  width: 105%;
}

.image-container img {
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.certification-links {
  padding-left: 0px;
}

.certification-links li {
  list-style-type: none;
  margin-top: 10px;
}

.certification-links li a {
  color: #444;
  text-decoration: underline;
}

.sidebar-view {
  min-width: unset !important;
}

.thumbnail {
  min-height: 300px;
}

.thumbnail img {
  width: 50%;
  height: 50%;
  display: block;
  margin-top: 15%;
}

.caption h1 {
  margin-top: 0;
}

.underline-space {
  padding-bottom: 5px;
}

.card-height {
  min-height: 300px;
}

.card-picture {
  min-width: 100%;
  min-height: 100%;
}
