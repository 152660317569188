.pmo{
    min-height: 100%;
}

@media (min-width: 768px){
  .pmo-content{
    padding: 15px
  }
}

.pmo-content .col_business_development_likelihood{width: 70px;}
.pmo-content .col_is_proposed{width: 100px;}
.pmo-content .col_is_accepted{width: 100px;}
.pmo-content .col_amount{width: 85px;}
.pmo-content .col_weighted_amount{width: 95px;}
.pmo-content .col_start_date{width: 100px;}

.pmo-content .projects {
  cursor: pointer;
}

.pmo-content .title-box {
  display: inline-block;
}

.pmo-content .title-box h1, .pmo-content .title-box h2 {
  display: block;
  line-height: normal;
}

.pmo-content .title-section button {
  vertical-align: top;
}

.pmo-content .title-box h2 {
 font-size: 15px;
 color: #777;
 margin-top: 5px;
}
