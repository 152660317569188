.checkbox span{
  margin-left: 5px;
}

.radioLabel{
  margin-right: 10px;
  margin-left: 3px;
}

.calendar-field {
  position: relative;
}

.calendar-field input {
  padding-right: 25px;
}

.calendar-field .icon{
    position: absolute;
    top: 31px;
    right: 10px;
    color: #555
}

.box-header .only_missing{
  height: 60px;
}

.box-header .only_missing{
  
}
