.users-content{
    min-height: 100%;
    padding: 15px;
}

.users-content .no-role{
  background: rgba(251, 128, 128, 0.87);
}

.users-content .inactive {
  background: rgba(253, 231, 163, 0.87);
}

.levels-content  .calendar-field {
  margin-top: 10px;
}

.levels-content .calendar-field .icon {
  top: 7px;
}

.levels-content  .calendar-field label{
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
}

.levels-content  .calendar-field .field-calendar{
    display: inline-block;
    position: relative;
}

.levels-content  .calendar-field .save {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 2px;
}
