.skin-blue .main-sidebar{
    padding-top: 108px;
    background-color: #252C35;
    height: 100%;
    overflow-y: auto;
}

@media (min-width: 768px ) {
  .skin-blue .main-sidebar{
      padding-top: 54px;
  }
}

.skin-blue .main-header .logo {
  background: #252C35;
}

.skin-blue .sidebar a {
  color: #fff;
}

.skin-blue .sidebar a:hover {
  color: #EC7A52;
}

.skin-blue .sidebar .current{
  /* background: #367fa9 */
}

.skin-blue .sidebar .current a{
  color: #EC7A52
}

.skin-blue .sidebar .current:hover, .skin-blue .sidebar .current:hover a{
  /* background: #367fa9 */
}

.user-panel>.info{
  width: 75%;
}

.user-panel>.info p{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.nav-info span{
  font-size: 12px;
  font-style: italic;
  display: block;
  color: #fff;
  padding: 5px 5px 5px 15px;
}
