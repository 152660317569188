.cn-sessions{
    min-height: 100%;
}

@media (min-width: 768px){
  .cn-sessions-content{
    padding: 15px
  }
}

/*
.pmo-content .col_business_development_likelihood{width: 70px;}
.pmo-content .col_is_proposed{width: 100px;}
.pmo-content .col_is_accepted{width: 100px;}
.pmo-content .col_amount{width: 85px;}
.pmo-content .col_weighted_amount{width: 95px;}
.pmo-content .col_start_date{width: 100px;}
*/

.cn-sessions-content .projects {
  cursor: pointer;
}

.cn-sessions-content .title-box {
  display: inline-block;
}

.cn-sessions-content .title-box h1, .cn-sessions-content .title-box h2 {
  display: block;
  line-height: normal;
}

.cn-sessions-content .title-section button {
  vertical-align: top;
}

.cn-sessions-content .title-box h2 {
  font-size: 15px;
  color: #777;
  margin-top: 5px;
}
