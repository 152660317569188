/* .home-view{

}

.home-view .links-row{

}

.home-view .col{

}
*/
.flex-column{
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.home-view .box{
  height: auto;
  min-height: 251px;
}

.home-view .white-box {
  /*height: 230px;*/
  height: auto;
  padding: 15px;
  border-top: none;
}

.home-view .border-orange {
  border-bottom: solid 1px #e75b27;
  padding-left: 0;
}

.home-view .color-box a {
  display: block;
  height: 230px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-view .color-box.pmo {
    min-height: 125px;
}

.home-view .color-box.pmo button {
  height: 115px;
  display: block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: 0;
  align-items: center;
}

.home-view .color-box.pmo button:hover, .home-view .color-box.pmo button:active, .home-view .color-box.pmo button:focus {
  outline: none;
}

.home-view .black-box  {
  background-color: #252C35;
}
.home-view .orange-box {
  background-color: #ec7b48;
}
.home-view .lightblue-box {
  background-color: #85cfef;
}

.home-view .purple-box {
  background-color: #859aef;
}

.home-view .color-box a i, .home-view .color-box button i {
  display: block;
  font-size: 65px;
}
.home-view .color-box a span {
  display: block;
  padding-top: 25px;
}

.home-view .color-box.pmo button span {
  display: block;
  padding-top: 0;
}

.home-view .title-staff {
  display: block;
  position: relative;
  top: 23px;
}

.home-view .color-box a .white-logo {
  margin: 0px auto;
  width: 110px;
}

.home-view .color-box.pmo  button .white-logo {
  margin: 0px auto;
  width: 75px;
}

.home-view .circular_link {
  height: 180px;
}

.home-view .circular_link a {
  display: block;
  height: 100%;
  color: #505050;
  text-align: center;
}

.home-view .circular_link a img {
  display: block;
  width: 90px;
  margin: 20px auto 10px auto;
}

.home-view .circular_link a span {
  display: block;
  font-size: 15px;
  font-weight: bold;
}

.right-pane {
  padding: 15px;
  height: 100%;
  margin-bottom: 0px;
  min-height: 930px;
}

.right-pane .box-title {
  color: #e75c0b;
}

.sidebar-outer {
  padding: 0px;
  height: 100%;
}

.content-wrapper {
  display: flex;
}

.home-container {
  min-width: 100%;
}

.box-body > ol {
  padding-left: 20px;
}
