form .buttons button{
  margin-right: 10px;
}

.form-inline .form-group.field{
  margin-right: 10px;
}

.field.inline label {
  display: inline-block;
}

.field.inline div {
  display: inline-block;
}

.form{
  padding: 0;
}

@media (min-width: 768px) {
  .form{
    padding: 10px;
  }
}

@media (max-width: 768px) {
  fieldset{
    padding: 0;
  }

  .form-group{
    margin-bottom: 5px;
  }
}

#active{
  width: 95px
}
