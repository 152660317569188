.pmoProjects-container .small-box {
  background: #FFF;
}

.pmoProjects-container .small-box:hover {
  color: initial;
}

.pmoProjects-container .small-box>.small-box-footer {
  background: #EC7A52;
  width: 100%;
  top: 3px;
  border: 0;
}

.pmoProjects-container .small-box>.small-box-footer:hover {
  background: rgb(236, 122, 82, 0.65);
}

.pmoProjects-container {
  flex: 1;
}


.pmoProjects-container .row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}

.pmoProjects-container .row-eq-height.margin-top {
  margin-top: 15px;
}

.pmoProjects-container .row-eq-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.pmoProjects-container .small-box {
  height: 100%;
  text-align: center;
}

.pmoProjects-container .big-number {
  font-size: 25px;
  text-align: center;
  color: #EC7A52;
  padding: 15px 0;
}

.pmoProjects-container .secondary-title {
  text-align: center;
  margin-top: 10px;
}

.pmoProjects-container h3 {
  text-align: left;
  border-bottom: #E75B27 solid 1px;
  font-size: 16px;
}

.pmoProjects-container .title-box {
  margin: 15px 0;
}

.pmoProjects-container .title-box h1 {
  font-size: 20px;
  margin-bottom: 0;
}

.pmoProjects-container .title-box h2 {
  font-size: 15px;
  border-bottom: 0;
  margin: 0;
  margin-top: 5px;
  color: #777;
}

.pmoProjects-container .download-button {
  text-align: right;
}

.pmoProjects-container .download-ppt {
  text-align: right;
}

.modal-content .msg.error {
  color: red;
}


@media (max-width: 1200px) {
  .pmoProjects-container h3 {
    font-size: 1.2rem;
  }

  .pmoProjects-container .secondary-title {
    font-size: 12px;
  }

  .knob-percentage {
    font-size: 22px;
  }
}

