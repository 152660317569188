body {
  margin: 0;
  padding: 0;
}

.root-content, main, .login-page, .password-recovery {
  height: 100%;
  overflow: hidden;
  background: #fff !important;
}

main{
  padding-top: 108px;
}

@media (min-width: 768px ) {
  main{
    padding-top: 54px;
  }
}

.content-wrapper{
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.section-content{
  min-height: 100%;
}

.pageError{
  padding: 35px;
}

.section-list-content{
  min-height: 400px;
  padding: 15px
}

.title-section{
  margin-top: 15px;
}

.title-section h1{
  display: inline-block;
  vertical-align: middle;
}

.title-section button{
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.title-section h2{
  font-size: 16px;
  margin: 0;
  line-height: 1;
  display: inline-block;
}

.title-section h2.sub-header{
  text-align: right;
  width: 100%;
  padding-right: 10px;
  margin-top: 5px;
}

.form{
  padding: 10px;
}

@media (max-width: 768px){
/*@media (max-width: 960px){*/

  .box-header .box-title{
    margin-bottom: 5px;
    font-size: 15px;
    font-size: 20px;
  }
}

.box .box-header .box-title{
  font-size: 20px;
}

.form{
  padding: 10px;
}

.totals{
  margin-top: 15px;
  border-top: 1px solid #e0e0e0;
  padding: 15px;
}

.action-section {
  text-align: right;
}

.action-section .field{
  text-align: left;
}

.action-section .fieldset.buttons{
  padding-top: 22px;
}

.content-wrapper section{
  min-height: 100%;
  padding: 15px;
}

.content-wrapper > section {
  min-width: 100%;
}

.content-wrapper > section.minWidth {
  min-width: 1100px;
}
