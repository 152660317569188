.actions button:first-of-type{
  margin-right: 5px;
}

.react-bs-container-body{
  overflow-x: hidden !important;
}


.tr-odd{
  background-color: #f9f9f9;
}


.fixed-header{
  position: sticky;
  top: 0;
  background: #FFF;
  z-index: 9;
}

/*
table th{
  font-size: 11px;
}
*/
