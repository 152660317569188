.login-logo {
    padding-bottom: 32px;
}

.login-logo img {
    width: 300px;
}

.btn-hs-orange {
    color: #fff;
     background-color: #EC7A52;
     border: none;
 }

.login .form-control {
    border: none;
    border-bottom: 1px solid #E9E9F0;
}
