.filedrop {
  width: 100%;
  position: relative;
  height: 100px;
  border-width: 2px;
  border-color: #e9e9f0;
  border-style: dashed;
  border-radius: 5px;
  margin: 10px 0;
}

.filedrop.active {
  border-color: #3c8dbc;
}

.filedrop-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c8c8cc;
}

.filedrop.active .filedrop-content {
  color: #3c8dbc;
}

.files-title {
  font-weight: 700;
  font-size: 12px;
}

.files li {
  padding: 5px;
  font-size: 11px;
}
