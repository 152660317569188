.business-development-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .business-development-content{
    padding: 15px
  }
}

.business-development-content .col_bd_probability{width: 80px;}
.business-development-content .col_amount{width: 90px;}
.business-development-content .col_weighted_amount{width: 100px;}
.business-development-content .col_close_date{width: 110px;}
