
.right-pane .project-likelihood-content{
	min-height: auto;
}
.right-pane .project-likelihood-content .box{
	border: none;
}

.right-pane .project-likelihood-content thead{
	display: none;
}

.right-pane .project-likelihood-content .react-bs-table {
	border: none;
}

.right-pane .project-likelihood-content .react-bs-table tr{
	background-color: #fff;
	border: none;
}

.right-pane .project-likelihood-content .react-bs-table tr td{
	border: none;
	padding: 10px 0 0;
	border-top: 1px solid #f4f4f4;
}

.right-pane .project-likelihood-content .react-bs-table tr:first-child{
	border-top: none;
}

.Announcement-Container{
	height: 90%;
}