.consultant-skills-content .col_skill_metric_header{width: 120px;}
.consultant-skills-content .col_skill_metric{width: 60px;}

.update-consultant-skills-fieldset{
  max-width: 450px;
} 

.update-consultant-skills-fieldset .form-group{
  width: 180px;
  margin-right: 20px;
  float: left;
}