.consultant-locations-content{
    min-height: 100%;
}


.consultant-locations-content .date {
  color: #ababab;
  font-size: 11px;
}

@media (min-width: 768px){
  .consultant-locations-content{
    padding: 15px
  }
}

.form-inline {
  display: inline-block;
}

.section-list-content .editor.edit-text {
  border: 1px solid #dcdcdc;
}

.favorites {
  width: 180px;
}

.consultant-locations-content .location-select input{
  border-radius: 4px;
}

.consultant-locations-content .dropdown-menu {
  border: 1px solid rgba(0,0,0,.15);
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.consultant-locations-content .dropdown-menu .selected button {
  background-color: #eee;
  color: #333;
}

.consultant-locations-content .dropdown-menu .highlihted button {
  background-color: #f5f5f5;
  color: #333;
}


.consultant-locations-content .dropdown-menu button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  background: none;
  border: 0;
  width: 100%;
  text-align: left;
}
