.system-msg {
  background-color: #2E3C54;
  bottom: 20px;
  border-radius: 2px;
  color: #FFF;
  right: 20px;
  padding: 15px 20px;
  position: fixed;
  z-index: 9999;
}

.system-msg p {
    margin: 0;
}

.system-msg-spinner {
    height: 25px;
    width: 25px;
    animation: loading 1.2s infinite linear;
    border-radius: 50%;
    background: transparent;
    border-top: 2px solid #FFF;
    border-right: 2px solid #FFF;
    border-bottom: 2px solid #777;
    border-left: 2px solid #777;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

.transition-system-msg-appear {
  opacity: 0.01;
}

.transition-system-msg-appear.transition-system-msg-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.transition-system-msg-leave {
  opacity: 1;
}

.transition-system-msg-leave.transition-system-msg-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes loading {
	to { transform: rotate(360deg); }
}
