.permissionsTable {
  width: 100%;
}

.permissionsTable td {
  border: 1px solid #f4f4f4;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  text-align: center;
}

.permissionsTable th {
  border: 1px solid #f4f4f4;
  padding: 8px;
  line-height: 1.42857143;
  border-bottom-width: 2px;
}

.permissionsTable .onlyUser {
  background: #3c8dbc;
}

.permissionsTable .icheckbox_square-orange {
  background-color: #FFF;
}

.permissionsTable .icheckbox_square-orange.disabled {
  background-color: #cecece;
}

.permissionsTable td.title {
  text-align: left;
}

.tr-odd {
  background-color: #f9f9f9;
}
