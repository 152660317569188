.utilization-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .utilization-content{
    padding: 15px
  }
}

.utilization-content .col_billable_total{width: 120px;}
.utilization-content .col_role_target{width: 80px;}
.utilization-content .col_available_total{width: 130px;}
.utilization-content .col_used_total{width: 105px;}
