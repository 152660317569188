.rates-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .rates-content{
    padding: 15px
  }
}


.rates-content .button-new{
  margin-top: 23px;
}
