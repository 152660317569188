.main-header{
  position: fixed;
  width: 100%;
}

.skin-blue .main-header .logo:hover {
    background: #1a2226;
}

.main-header .logo, .sidebar-toggle {
  height: 54px;
}

.sidebar-toggle{
  border-radius: 0;
}

.sidebar-toggle:active, .sidebar-toggle:focus, .sidebar-toggle:hover{
  text-decoration: none;
}
.skin-blue .main-header .navbar {
  background: #E75B27;
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background: #CE4F20;
}

.skin-blue .main-header .navbar .nav>li>button{
  color: #fff;
}
.main-header .navbar .nav>li>button:hover{
  background: rgba(0,0,0,0.1);
  color: #f6f6f6;
}

.main-header .navbar .settings>a{
  padding-top: 18px;
}

.main-header .navbar .settings>a span{
  font-size: 18px;

}

.main-header .navbar .settings>.dropdown-menu a span{
  width: 15px;
  color: #686868;
}
.main-header .navbar .settings>.dropdown-menu>li>a{
  color: #686868;
}

@media (min-width: 768px){
  .navbar-nav>li>button {
      padding-top: 15px;
      padding-bottom: 15px;
  }
}

@media (max-width: 991px){
  .navbar-custom-menu .navbar-nav>li>button {
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 20px;
  }
}
