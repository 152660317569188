.stock-options-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .stock-options-content{
    padding: 15px
  }
}

.stock-options-content .inactive{
  background: rgba(253, 231, 163, 0.87);
}
