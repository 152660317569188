.realization-content{
  min-height: 100%;
}

@media (min-width: 768px){
  .realization-content{
    padding: 15px
  }
}
.realization-content #realization-tabs{margin: 10px 10px 0px 10px;}
.realization-content .react-bs-table-expand-cell{width: 30px;}

.realization-content .col_month{width: 78px;}
.realization-content .col_time{width: 65px;}
.realization-content .col_expenses{width: 97px;}
.realization-content .col_total{width: 97px;}
.realization-content .col_invoiced{width: 108px;}
.realization-content .col_realization{width: 106px;}

.realization-content .col_role{width: 150px;}
.realization-content .col_hours{width: 70px;}
.realization-content .col_rate{width: 100px;}
.realization-content .col_expenses{width: 110px;}
.realization-content .col_total{width: 100px;}
