
.Announcement{
	line-height: 22px;
	border-bottom: 1px solid #f4f4f4;
	padding: 10px 15px 5px;
	border-left: 3px solid transparent;
	transition: height ease-out 0.3s;
	display: block;
	cursor: pointer;
}

.Announcement.highlight {
	  background: #ffd2c1;
}

.Announcement .date{
	display: block;
	font-weight: 700;
	font-size: 12px;
	color: #27b4e7;
}

.Announcement .content {
	min-height: auto;
	padding: 0;
	display: inline-block;
}

.Announcement-Container{
	overflow-y: scroll;
	height: 300px;
}
