.small-box {
  background: #FFF;
}

.small-box:hover {
  color: initial;
}

.small-box>.small-box-footer {
  background: #EC7A52;
}

.small-box>.small-box-footer:hover {
  background: rgb(236, 122, 82, 0.65);
}

.dashboard-container {
  flex: 1;
}

.dashboard-container .knob {
  position: relative;
}

.dashboard-container .knob .knob-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
}

.dashboard-container .row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}

.dashboard-container .row-eq-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.dashboard-container .small-box {
  height: 100%;
  text-align: center;
}

.dashboard-container .big-number {
  font-size: 25px;
  text-align: center;
  color: #EC7A52;
  padding: 15px 0;
}

.dashboard-container .secondary-title {
  text-align: center;
  margin-top: 10px;
}

.dashboard-container h2 {
  text-align: left;
  border-bottom: #E75B27 solid 2.5px;
}



@media (max-width: 1200px) {
  .dashboard-container h2 {
    font-size: 1.2rem;
  }

  .knob-percentage {
    font-size: 22px;
  }

  .row > .margin-top {
    margin-top: 10px;
  }
}
