.search-control {
  border-radius: 15px !important;
  border-bottom: 0;
}

.table-spacing {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.cell-style {
  background-color: white;
  height: 7em;
}

.cell-style>td:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cell-style>td:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table>thead>tr>th {
  border-bottom: 0;
}

.column-data {
  vertical-align: middle !important;
  padding-left: 3em !important;
}

.column-data-2 {
  vertical-align: middle !important;
  padding-left: 2em !important;
}

.header-padding:first-child {
  padding-left: 0 !important;
}

.resource-id {
  width: 60px;
}
