.ReactModal__Overlay--after-open{
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#report_type{
  width: 160px;
}

.copy-billability{
  text-align: right;
}

@media (min-width: 768px){
  .staffing-content{
    padding: 15px
  }
}

.staffing-content #staffing-tabs{margin: 10px 10px 0px 10px;}
.staffing-content .react-bs-table-expand-cell{width: 30px;}

.staffing-content .col_utilization{
  width: 67px; 
  font-size: 11px;
  text-transform: uppercase;
}
.staffing-content .col_availability{width: 67px;}

.staffing-content .col_availability.empty, 
.staffing-content .col-availability,
.staffing-content .dateParent.empty, 
.staffing-content .col_availability_edit.empty {
  background: #ccc;
  color: #FFF;
}
/* Col Level */
.staffing-availability-list-content .col-level{width: 210px;}
/* Availability Background Colors */
.staffing-availability-list-content .availability-1{background-color: #c6e4b2;}
.staffing-availability-list-content .availability-2{background-color: #a0d080;}
.staffing-availability-list-content .availability-3{background-color: #90c070;}
.staffing-availability-list-content .availability-4{background-color: #80b060;}
.staffing-availability-list-content .availability-5{background-color: #70a050;}
.staffing-availability-list-content .availability-6{background-color: #609040;}
.staffing-availability-list-content .availability-7{background-color: #508030;}
.staffing-availability-list-content .availability-8{background-color: #407020;}
.staffing-availability-list-content .availability-9{background-color: #306010;}
.staffing-availability-list-content .availability-10{background-color: #2d4201;}
/* Availability Text Colors */
.staffing-availability-list-content .availability-8,
.staffing-availability-list-content .availability-9,
.staffing-availability-list-content .availability-10{ color: #c6e4b2;}
/* Availability Col Width */
.staffing-availability-list-content .col-availability-header{
  width: 67px;
  font-size: 11px;
  text-transform: uppercase;
}
.staffing-availability-list-content .col-availability{ width: 67px; }

/* editor in table view */
.dateParent {position: static !important;}
.dateParent .rdt.rdtOpen input{border: 1px solid !important;}
.dateParent .rdt input{border: 0px;}
.rdt {position: static !important;}
.rdtOpen .rdtPicker {right: 20px;}

.form-group .rdtOpen .rdtPicker  {right: auto;}
.rdtPicker table {table-layout: initial;}

