.profit-sharing-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .profit-sharing-content{
    padding: 15px
  }
}

.profit-sharing-content .inactive{
  background: rgba(253, 231, 163, 0.87);
}

.profit-sharing-content .col_total_value_per_point{width:130px;}
.profit-sharing-content .col_average_points{width:105px;}
.profit-sharing-content .col_total_points{width:80px;}
.profit-sharing-content .col_total_profit_sharing{width:120px;}
