.invoices-issued-content{
    min-height: 100%;
}

@media (min-width: 768px){
  .invoices-issued-content{
    padding: 15px
  }
}

.unpaid{
  background: rgba(253, 231, 163, 0.87)
}

/* .invoices-issued-content .col_number{} */
/* .invoices-issued-content .col_issue_date{width: 105px;} */
/* .invoices-issued-content .col_amount{width: 165px;} */

.invoices-issued-content .invoice-is-open{
  background: rgba(253, 231, 163, 0.87)
}
.invoices-issued-content .invoice-is-late{
  background: rgba(253, 163, 163, 0.87)
}

.invoices-issued-content .status-hint{
  padding: 10px;
}